import React, { useState } from 'react';
import { getBackgroundColor } from '../../../utils/getBackgroundColor';

const ComparisonCard = ({ actual, scenarios, setScenarios }) => {
  const removeScenario = (id) => {
    setScenarios(scenarios.filter((scenario) => scenario.id !== id));
  };

  const fields = [
    { label: 'Scope', name: 'scope' },

    { label: 'Residual Risk', name: 'severity' },
    { label: 'Avg. Loss Exposure', name: 'average_lose_exposure' },
    { label: 'Probability', name: 'probability_of_occurrence' },
    { label: 'Cost Savings', name: 'total_cost_saving' },
    { label: 'Investment Requirement', name: 'investment_requirement' },
    { label: 'Return on Investment (ROI)', name: 'return_on_investment' },
    // { label: "", name: "More..." },
  ];

  return (
    <div className="container mx-auto p-4">
      <div className="grid grid-cols-4 gap-4">
        <div className="font-bold text-center">Metrics</div>
        {/* <div className="font-bold text-center">Actual</div> */}
        {scenarios?.map((scenario) => (
          <div
            className="flex justify-between items-center font-bold text-center"
            key={scenario.id}
          >
            {scenario.senario_name}
            <button
              onClick={() => removeScenario(scenario.id)}
              className="ml-2 text-red-500"
            >
              ✕
            </button>
          </div>
        ))}
      </div>

      {fields?.map((field, index) => (
        <div key={index} className="grid grid-cols-4 gap-4 mt-4">
          <div className="font-semibold mr-2">{field.label}</div>
          {scenarios?.map((scenario) => (
            <div key={scenario.id}>
              {field.name === 'severity' ? (
                <p
                  className="w-32 p-3 text-center rounded-xl"
                  style={{
                    backgroundColor: getBackgroundColor(scenario[field.name]),
                    color: 'black',
                  }}
                >
                  {/* {scenario[field.name]} */}
                </p>
              ) : (
                scenario[field.name]
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ComparisonCard;
